<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'AutoLogin'
}
</script>
<script setup>
import {useRoute, useRouter} from 'vue-router'
import {getReturnUrl, setLoginType, setUserInfo, updateUserInfo} from "../../api/cookie";
import XEUtils from "xe-utils";

const route = useRoute()
const router = useRouter()

setUserInfo({token: route.params.token})
setLoginType(XEUtils.get(route,'query.login_type','mobile'))

updateUserInfo().then(da => {
  if (XEUtils.isString(route.query.return_page_name)) {
    router.push({name: route.query.return_page_name})
    return
  }
  router.push(getReturnUrl())
})
</script>

<style scoped>

</style>
